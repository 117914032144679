import './App.css';
import React, {createContext, useReducer} from "react";
import {Route, Routes} from "react-router-dom";
import {LoginForm} from "./pages/Login";
import {ThemeProvider} from "@mui/material/styles";
import {APP_INITIAL_STATE, AppReducer, initAppState} from "./reducers/AppReducer";
import {Home2} from "./pages/Home2";
import CffVota from "./pages/Vota";
import {Iscriviti} from "./pages/Iscriviti";
import moment from "moment/moment";
import CortoInGara from "./pages/CortoInGara";
import VotazioneGiuriaQualita from "./pages/VotazioneGiuriaQualita";
import VotazioneGiuriaGiovani from "./pages/VotazioneGiuriaGiovani";
import {IscrivitiQualita} from "./pages/IscrivitiQualita";
import {Programma} from "./pages/Programma";
import {ChiSiamo} from "./pages/ChiSiamo";

export const AppContext = createContext();
moment.locale("it");

function App() {
    const [state, dispatch] = useReducer(AppReducer, APP_INITIAL_STATE, initAppState);

    return (
        <AppContext.Provider value={{state: state, dispatch: dispatch}}>
            <ThemeProvider theme={state.theme}>
                <Routes>
                    <Route path="/" element={<Home2/>}/>
                    <Route path="/home" element={<Home2/>}/>
                    <Route path="/chisiamo" element={<ChiSiamo/>}/>
                    <Route path="/contatti" element={<Home2/>}/>
                    <Route path="/programma" element={<Programma/>}/>
                    <Route path="/cortometraggiingara" element={<CortoInGara/>}/>
                    <Route path="/vota" element={<VotazioneGiuriaGiovani/>}/>
                    <Route path="/giuriaqualita" element={<VotazioneGiuriaQualita/>}/>
                    <Route path="/login" element={<LoginForm/>}/>
                    <Route path="/iscriviti" element={<Iscriviti/>}/>
                    <Route path="/portalequalita" element={<IscrivitiQualita/>}/>
                </Routes>
            </ThemeProvider>
        </AppContext.Provider>
    );
}

export default App;
