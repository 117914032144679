import React, {useState} from 'react';
import {Button, CircularProgress, IconButton, InputAdornment, Paper, Snackbar, useMediaQuery} from '@mui/material';
import {styled} from '@mui/system';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useForm} from "react-hook-form";
import CTextField from "../components/CTextField";
import ResponsiveAppBar2 from "../components/ResponsiveAppBar2";
import {BaseStyle} from "../styles/v2/BaseStyle";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/system/Unstable_Grid";
import MuiAlert from "@mui/material/Alert";
import {getRoles} from "../utils/ConfigUtils";


const StyledPaper = styled(Paper)({
    maxWidth: 400,
    padding: 20,
    marginTop: 150,
    marginRight: 'auto',
    marginLeft: 'auto',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});
const StyledButton = styled(Button)({
    width: '100%',
    marginTop: 20,
    borderRadius: 20,

});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const {control, handleSubmit} = useForm();
    const classes = BaseStyle();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function onSubmit(data) {
        setLoading(true);
        let user = {
            codF: data.username,
            password: data.password
        }
        axios
            .post("/wiml-api/API/auth/login", user)
            .then((response) => {
                sessionStorage.setItem("token", response.data?.token);

                if (getRoles().includes("ROLE_GIURIAGIOVANI")) {
                    navigate("/vota");
                } else if (getRoles().includes("ROLE_GIURIAQUALITA")) {
                    navigate("/giuriaqualita");
                } else if (getRoles().includes("ROLE_ADMIN")) {
                    setSeverity("info");
                    setMessage("Sei entrato come admin, il redirect è disabilitato.");
                    setOpen(true);
                }
            })
            .catch(error => {
                setSeverity("error");
                setMessage("Nome utente e/o password non validi!");
                setOpen(true);
                console.log(JSON.stringify(error))
            })
        setLoading(false);
    }


    return (
        <React.Fragment>
            <main className={classes.main}>
                <ResponsiveAppBar2/>
                <form onSubmit={handleSubmit(data => onSubmit(data))}>
                    <StyledPaper>
                        <h2>Accesso portale Giurato</h2>
                        <CTextField
                            label="Username"
                            name="username"
                            type="Text"
                            helperText={'Il tuo codice giurato'}
                            control={control}
                            shrink={!isMobile ? !isMobile : undefined}

                        />
                        <CTextField
                            label="Password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            helperText={'La tua chiave di accesso'}
                            control={control}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword}>
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            shrink={!isMobile ? !isMobile : undefined}
                        />

                        <Grid xs={12}>
                            {loading ? <CircularProgress/> : <StyledButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                            >
                                LOGIN
                            </StyledButton>}

                        </Grid>
                    </StyledPaper>
                </form>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                    <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
            </main>
        </React.Fragment>
    );
};
