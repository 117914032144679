import {makeStyles} from "@mui/styles";


export const BaseStyle = makeStyles(theme => ({
	main: {
		// backgroundColor: theme.palette.type === "light" ? "#c3e3ff" : theme.palette.background.default,
		// backgroundColor: theme.palette.background.default,
		backgroundColor: theme.palette.type === "light" ? "rgba(255, 255, 255, 0.4)" : "rgba(0,0,0,0.4)",
		minHeight: "100vh",
		width: "100%",
		position: "absolute",
		paddingBottom: 20,
		"-webkit-scrollbar": {
			display: "none"
		}
	},
	mainBg: {
		//backgroundColor: theme.palette.type === "light" ? "#c3e3ff" : theme.palette.background.default,
		//backgroundColor: theme.palette.background.default,
		backgroundColor: "rgba(84,162,253,0.55)",
		minHeight: "100vh",
		width: "100%",
		position: "absolute",
		paddingBottom: 20,
		"-webkit-scrollbar": {
			display: "none"
		}
	},
	mainNoBgCol: {
		//backgroundColor: theme.palette.background.default,
		minHeight: "100vh",
		width: "100%"
	},

	logo: {
		width: theme.spacing(20),
		height: theme.spacing(20),
		cursor: "pointer",
		// [theme.breakpoints.down('sm')]: {
		// 	display: "none"
		// },
	},
}))
