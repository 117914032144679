import React, {useState} from 'react';
import {BaseStyle} from "../styles/v2/BaseStyle";
import Grid from "@mui/system/Unstable_Grid";
import {styled} from "@mui/system";
import {withTheme} from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Button, CircularProgress, Paper, Snackbar, useMediaQuery} from "@mui/material";
import ResponsiveAppBar2 from "../components/ResponsiveAppBar2";
import CTextField from "../components/CTextField";
import {useForm} from "react-hook-form";
import logo from "../assets/logo.svg";
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
import Footer from "../components/Footer";

const Item = styled(withTheme(Paper))(props => ({
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '60px',
    elevation: 4,
    borderRadius: 20,
    padding: 20,
    color: props.theme.palette.type === "light" ? 'black' : 'white',
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Iscriviti = () => {
    const classes = BaseStyle();
    const isMobile = useMediaQuery('(max-width:600px)');
    const xsGridCentral = isMobile ? 12 : 6;
    const {control, handleSubmit} = useForm();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    function onSubmit(data) {
        console.log(data);
        setLoading(true)
        data.tipo = 'GIOVANI';

        if (new Date() >= new Date("07/01/2024") && new Date() <= new Date("08/14/2024"))
            axios
                .post("/wiml-api/API/CFF/iscriviti", data)
                .then((response) => {
                    // alert(response.data ? response.data : "Grazie per la tua votazione!");
                    setLoading(false)
                    if (response.status === 200) {
                        setSeverity("success");
                        setMessage("Complimenti, iscrizione effettuata con successo!");
                        setOpen(true);
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    setSeverity("error");
                    setMessage(error.response.data.message);
                    setOpen(true);
                })
        else if (new Date() < new Date("07/01/2024")) {
            // alert('Le iscrizioni non sono ancora aperte, torna a trovarci!');
            setLoading(false)
            setSeverity("error");
            setMessage("Le iscrizioni non sono ancora aperte, torna a trovarci!");
            setOpen(true);
        } else if (new Date() > new Date("08/14/2024")) {
            // alert('Le iscrizioni sono chiuse, sarà per il prossimo anno!');
            setLoading(false)
            setSeverity("error");
            setMessage("Le iscrizioni sono chiuse, sarà per il prossimo anno!");
            setOpen(true);
        }

    }

    return (
        <React.Fragment>
            <main className={classes.main}>
                <ResponsiveAppBar2/>
                <Container maxWidth="100%" component={"article"} style={{marginTop: 40}}>
                    <Grid container spacing={4}
                          style={{align: "center", justifyContent: 'center', alignItems: 'center'}}>

                        <Grid xs={xsGridCentral + 1}>
                            <Item elevation={4} width={'80%'}>
                                <img
                                    className={classes.logo}
                                    src={logo}
                                    alt={"logo"}
                                    style={{
                                        width: "300px",
                                        height: "250px",
                                        // transform: "scale(1.5)"
                                    }}
                                />
                                <Typography>
                                    Entra nel mondo del cinema, esplora l'arte del racconto e fatti sentire! <br/>
                                    Iscriviti a partire dal <b>20 Luglio</b> alla Giuria Giovani al Caselle Film
                                    Festival.<br/>
                                    Preparati a vivere un'esperienza unica e ad essere la voce dei futuri capolavori
                                    cinematografici!<br/>
                                </Typography>
                            </Item>
                        </Grid>


                        <Grid xs={xsGridCentral + 1}>
                            <Item elevation={4} width={'80%'}>
                                <Typography style={{fontWeight: 'bold', fontSize: '24px'}}>
                                    Compila per iniziare la tua esperienza da giurato!
                                </Typography>
                                <form className={classes.root} onSubmit={handleSubmit(data => onSubmit(data))}>
                                    <Grid container spacing={2}>
                                        {/*<Grid xs={12}>*/}
                                        {/*    <CTextField label={"Tipo giurato"}*/}
                                        {/*                name={"tipo"}*/}
                                        {/*                required={true}*/}
                                        {/*                control={control}*/}
                                        {/*                type={'autocomplete'}*/}
                                        {/*                options={["GIOVANI", "QUALITA"]}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}
                                        {/*<Typography fontSize={12} textAlign={"center"} width={'100%'}>*/}
                                        {/*    <b>Attenzione, verrà comunque effettuato un controllo di appartenenza*/}
                                        {/*        alla categoria indicata.</b>*/}
                                        {/*</Typography>*/}
                                        <Grid xs={12} sm={6}>
                                            <CTextField label={"Nome"} name={"nome"}
                                                        control={control}
                                                        required={true}
                                                        type={'text'}
                                            />
                                        </Grid>
                                        <Grid xs={12} sm={6}>
                                            <CTextField label={"Cognome"} name={"cognome"}
                                                        required={true}
                                                        control={control}
                                                        type={'text'}
                                            />
                                        </Grid>
                                        <Grid xs={12} sm={6}>
                                            <CTextField label={"Data di nascita"} name={"data_nascita"}
                                                        control={control}
                                                        type={'date'}
                                            />
                                        </Grid>
                                        <Grid xs={12} sm={6}>
                                            <CTextField label={"Email"} name={"email"}
                                                        required={true}
                                                        control={control}
                                                        type={'email'}

                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <CTextField label={"Cellulare"} name={"cellulare"}
                                                        required={true}
                                                        control={control}
                                                        type={'number'}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            {loading ? <CircularProgress/> : <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                            >
                                                Invia
                                            </Button>}

                                        </Grid>
                                    </Grid>
                                </form>
                            </Item>
                        </Grid>

                    </Grid>

                </Container>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                    <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
                <Footer/>
            </main>
        </React.Fragment>
    );
};

