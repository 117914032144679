import {
	GET_EVENTS_FAIL,
	GET_EVENTS_START,
	GET_EVENTS_SUCCESS,
	SET_CLOSE_SNACK,
	SET_LANG,
	SET_OPEN_SNACK,
	SET_ROLE,
	SET_TAB,
	SET_THEME
} from "./ActionTypes";
import {createTheme} from "@mui/material/styles";
import {blue, indigo, lightBlue} from "@mui/material/colors";
import {responsiveFontSizes} from "@mui/material";

export const theme = (themeValue) => {
	return createTheme({
		palette: {
			secondary: {
				main: themeValue === "light" ? lightBlue[200] : lightBlue[900],
				light: lightBlue["A100"],
				dark: lightBlue["A700"],
			},
			primary: {
				main: themeValue === "light" ? indigo["A700"] : lightBlue[500],
				light: blue["A200"],
				dark: blue["900"],
			},
			background: {
				paper: themeValue === "light" ? "#fff" : "#303030",
				default: themeValue === "light" ? "#fafafa" : "#262626",
			},
			type: themeValue,
		},
		overrides: {
			MuiTooltip: {
				tooltip: {
					fontSize: "1em",
				}
			},
			MuiPickersCalendarHeader: {
				switchHeader: {
					textTransform: 'uppercase',
				},
				dayLabel: {
					textTransform: 'uppercase',
				},
			},
			MuiCssBaseline: {
				"@global": {
					body: {
						scrollBarHeight: 10,
						scrollbarColor: "#6b6b6b #2b2b2b",
						"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
							backgroundColor: "rgba(255, 255, 255, 0)",
							width: 10,
							height: 10,
						},
						"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
							borderRadius: 24,
							backgroundColor: "#6b6b6b",
							minHeight: 24,
						},
						"&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
							backgroundColor: "#959595",
						},
						"&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
							backgroundColor: "#959595",
						},
						"&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
							backgroundColor: "#959595",
						},
						"&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
							backgroundColor: "rgba(255, 255, 255, 0)",
						},
					},
				},
			},
		},
		typography: {
			fontFamily: [
				'Jost',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif'
			].join(','),
			fontSize: 16,
		},
	})
}

export const initAppState = () => {
	let themeValue = "light";
	/*	if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			themeValue = "dark";
		}*/

	return {
		open: false,
		lang: "it",
		severity: "success",
		message: "",
		tab: 0,
		role: {},
		theme: theme(themeValue),
		messages: [],
		loadingGetMessages: true,
		errorGetMessages: "",
		loadingSendEmail: true,
		errorSendEmail: "",
		eventi: [],
		loadingGetEventi: true,
		errorGetEventi: "",
	}
}

export function setThemeType(type, dispatch) {
	let newTheme = theme(type);
	responsiveFontSizes(newTheme);
	dispatch({type: SET_THEME, payload: newTheme})
}

export const APP_INITIAL_STATE = {}

export const AppReducer = (state = APP_INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_LANG:
			return {
				...state,
				lang: action.payload
			}
		case SET_THEME:
			return {
				...state,
				theme: action.payload,
			}
		case SET_TAB:
			return {
				...state,
				tab: action.payload,
			}
		case SET_ROLE:
			sessionStorage.setItem("role", JSON.stringify(action.payload));
			return {
				...state,
				tab: 0,
				role: action.payload
			}
		case SET_OPEN_SNACK:
			return {
				...state,
				open: true,
				severity: action.severity,
				message: action.message,
			}

		case SET_CLOSE_SNACK:
			return {
				...state,
				open: false,
				message: "",
			}
		case GET_EVENTS_START:
			return {
				...state,
				eventi: [],
				loadingGetEventi: true,
				errorGetEventi: "",
			}
		case GET_EVENTS_SUCCESS:
			return {
				...state,
				eventi: action.payload,
				loadingGetEventi: false,
				errorGetEventi: "",
			}
		case GET_EVENTS_FAIL:
			return {
				...state,
				eventi: [],
				loadingGetEventi: false,
				errorGetEventi: action.error,
			}

		default:
			return state;
	}
}
