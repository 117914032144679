import React from "react";
import {Box, Container, Grid, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import {Facebook, Instagram, Twitter} from "@mui/icons-material";

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                p: 6,
                marginBottom: -20,
                marginTop: 40
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Su di noi
                        </Typography>
                        <Typography variant="body2" color="text.secondary">

                            Il CFF è un festival dedicato agli audiovisivi a tema ambientale e naturalistico che si
                            tiene a Caselle in Pittari, nel cuore del Parco Nazionale del Cilento e Vallo di Diano. Il
                            festival promuove la raccolta delle immagini più interessanti e premia gli sforzi e il
                            talento dei giovani e non solo, che si dedicano a raccontare l'importanza dell'ambiente.
                            Attraverso l'arte cinematografica, il CFF si propone di porre l'attenzione su ciò che accade
                            nel mondo e di promuovere la riflessione, la denuncia e lo sviluppo sostenibile. La
                            partecipazione alla rassegna e agli eventi è gratuita fino ad esaurimento posti.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Contattaci
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            CFF - Caselle Film Festival
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Via Sandro Pertini, 7
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            84030 Caselle in Pittari (SA)
                        </Typography>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Email
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            info@casellefilmfestival.it
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Seguici
                        </Typography>
                        <Link href="https://www.facebook.com/Casellefilmfestival" color="inherit">
                            <Facebook/>
                        </Link>
                        <Link
                            href="https://www.instagram.com/casellefilmfestival/"
                            color="inherit"
                            sx={{pl: 1, pr: 1}}
                        >
                            <Instagram/>
                        </Link>
                        <Link href="https://twitter.com/hashtag/CaselleFilmFestival?src=hashtag_click" color="inherit">
                            <Twitter/>
                        </Link>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {"Copyright © "}
                        <Link color="inherit" href="https://casellefilmfestival/">
                            Firelabs 2023-2026 - All Rights Reserved
                        </Link>{" "}
                        {new Date().getFullYear()}
                        {"."}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}