import React, {useEffect, useState} from 'react';
import {BaseStyle} from "../styles/v2/BaseStyle";
import ResponsiveAppBar2 from "../components/ResponsiveAppBar2";
import MainFeaturedPost from "../components/MainFeaturedPost";
import {Grid, Paper, Typography, useMediaQuery} from "@mui/material";
import Footer from "../components/Footer";
import wip from "../assets/work-in-progress.png";
import axios from "axios";
import FeaturedPost from "../components/FeaturedPost";


export const CortoInGara = () => {
    const classes = BaseStyle();
    const isMobile = useMediaQuery('(max-width:600px)');
    const isHorMobile = useMediaQuery('(max-width:1000px)');
    const [cortometraggi, setCortometraggi] = useState([]);
    const [ready, setReady] = useState(false);


    let xsDim = isMobile ? 12 : 8;
    xsDim = isHorMobile ? 12 : xsDim;

    useEffect(() => {
        axios
            .get("/wiml-api/API/CFF/getCortometraggi")
            .then((response) => {
                console.log(response.data);
                setCortometraggi(response.data);
                setReady(true);

            })
            .catch((error) => console.log("ERROR: " + error))
    }, [])


    return (
        <React.Fragment>
            <main className={classes.main}>
                <ResponsiveAppBar2/>

                <Grid container
                      spacing={0}
                      style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignCenter: 'center',
                          justifyContent: 'center',
                          alignContent: 'center'
                      }}>
                    <Grid item xs={12} margin={2}>
                        <MainFeaturedPost/>
                    </Grid>
                    {ready && cortometraggi.length === 0 ? <Paper style={{
                        padding: 20,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <Typography>
                            L'elenco ufficiale dei cortometraggi in gara per l'edizione 2024 sarà pubblicato nel
                            mese di Luglio, torna a trovarci!
                        </Typography>
                        <img
                            src={wip}
                            alt={"logo"}
                            style={{
                                width: 100,
                                height: 100,
                                margin: 20
                            }}
                        />
                    </Paper> : <React.Fragment></React.Fragment>}

                    {cortometraggi.map(corto =>
                        <Grid key={corto.id} item xs={xsDim} margin={2}>
                            <FeaturedPost key={corto.id} cortometraggio={corto}/>
                        </Grid>
                    )}

                    {/*<Grid xs={xsDim} margin={2}>*/}
                    {/*    <FeaturedPost/>*/}
                    {/*</Grid>*/}
                    {/*<Grid xs={xsDim} margin={2}>*/}
                    {/*    <FeaturedPost/>*/}
                    {/*</Grid>*/}
                    {/*<Grid xs={xsDim} margin={2}>*/}
                    {/*    <FeaturedPost/>*/}
                    {/*</Grid>*/}
                    {/*<Grid xs={xsDim} margin={2}>*/}
                    {/*    <FeaturedPost/>*/}
                    {/*</Grid>*/}
                </Grid>

                <Footer/>
            </main>
        </React.Fragment>
    );
};

export default CortoInGara;