import * as React from 'react';
import {useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {CssBaseline, IconButton, MenuItem, Typography, useMediaQuery} from "@mui/material";
import {AppContext} from "../App";
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from 'react-router-dom';


import logo from '../assets/logo.svg'
import {BaseStyle} from "../styles/v2/BaseStyle";
import Menu from "@mui/material/Menu";

const pages = ['Home', 'Chi Siamo', 'Cortometraggi in gara', 'Programma', 'Login'];

function ResponsiveAppBar2() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const appContext = useContext(AppContext);
    const classes = BaseStyle();
    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:600px)');

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const handlePageClick = (page) => {
        console.log(page)
        page = page.toLowerCase();
        navigate('/' + page.replaceAll(" ", ""))
        // navigate('/')
    };

    return (
        isMobile ? <AppBar
                position="static"
                style={{
                    marginBottom: 40,
                    height: "80px",
                    justifyContent: 'center',
                    margin: 'auto',
                    backgroundColor: 'white',
                    boxShadow: 'none'
                }}
            >
                <CssBaseline/>
                <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between"}}>
                    <Box>
                        <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            style={{padding: 0, color: 'black'}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElNav}
                            keepMounted
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            {pages.map((page, index) => (
                                <MenuItem key={index} onClick={() => handlePageClick(page)}>
                                    {page}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography color={"black"}>
                        Caselle Film Festival
                    </Typography>
                    <img
                        className={classes.logo}
                        src={logo}
                        alt={"logo"}
                        style={{
                            width: "50px",
                            height: "50px",
                            transform: "scale(1.5)"
                        }}
                    />
                </Toolbar>
            </AppBar>
            :
            // CASO MOBILE NO
            <AppBar
                position="static"
                style={{
                    marginBottom: 40,
                    height: isMobile ? "80px" : "180px",
                    justifyContent: isMobile ? 'center' : 'flex-start',
                    margin: 'auto',
                    backgroundColor: 'white',
                    boxShadow: 'none'
                }}
            >
                <CssBaseline/>
                <Container maxWidth="100%">
                    <Toolbar style={{flexGrow: 1}}>
                        <img
                            className={classes.logo}
                            src={logo}
                            alt={"logo"}
                            style={{
                                width: "200px",
                                height: "200px",
                                transform: "scale(1.4)"

                            }}
                        />

                        <Box sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly'
                        }}>
                            {
                                pages.map((page, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => handlePageClick(page)}
                                        sx={{my: 2, color: 'black', display: 'block', fontWeight: 'bold'}}
                                    >
                                        {page}
                                    </Button>
                                ))
                            }
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
    );

}


export default ResponsiveAppBar2;
