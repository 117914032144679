import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ResponsiveAppBar2 from "../components/ResponsiveAppBar2";
import Footer from "../components/Footer";
import {BaseStyle} from "../styles/v2/BaseStyle";
import {Button, CircularProgress, Container, Paper, Snackbar, useMediaQuery} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import logo from "../assets/logo.svg";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/system";
import {withTheme} from "@mui/styles";
import CTextField from "../components/CTextField";
import {useForm} from "react-hook-form";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import {getConfig, getRoles, wait} from "../utils/ConfigUtils";

const Item = styled(withTheme(Paper))(props => ({
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '60px',
    elevation: 4,
    borderRadius: 20,
    padding: 20,
    color: props.theme.palette.type === "light" ? 'black' : 'white',
}));
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const VotazioneGiuriaQualita = () => {
    let navigate = useNavigate();
    const classes = BaseStyle();
    const isMobile = useMediaQuery('(max-width:600px)');
    const isHorMobile = useMediaQuery('(max-width:1000px)');
    const xsGridCentral = isMobile ? 12 : 6;

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [submit, setSubmit] = useState(false);


    const [cortometraggi, setCortometraggi] = useState([]);

    // CTextFieldControlZone
    const {control, handleSubmit} = useForm();


    useEffect(() => {
        document.title = 'Caselle Film Festival'; // Imposta il nome desiderato
    }, []);

    useEffect(() => {
        axios
            .get("/wiml-api/API/CFF/getCortometraggi", getConfig())
            .then((response) => {
                console.log(response.data);
                setCortometraggi(response.data);

            })
            .catch((error) => console.log("ERROR: " + error))
    }, [])

    useEffect(() => {
        if (!getRoles().includes("ROLE_GIURIAQUALITA") && !getRoles().includes("ROLE_ADMIN")) {
            navigate("/login");
        }
    }, [navigate])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const onSubmit = (data) => {
        setLoading(true)
        let _data = {
            nomeFilm: data.corto,
            tipoGiurato: 'QUALITA',
            regia: data.regia,
            sceneggiatura: data.sceneggiatura,
            fotografia: data.fotografia,
            musica: data.musica,
            montaggio: data.montaggio,
            commento: data.commento,

        };

        axios
            .post("/wiml-api/API/CFF/vota", _data, getConfig())
            .then(async (response) => {
                setLoading(false)
                if (response.status === 200) {
                    setSeverity("success");
                    setMessage("Complimenti, votazione effettuata con successo!");
                    setOpen(true);
                }

                await wait(3000)
                window.location.reload();

            })
            .catch((error) => {
                setLoading(false)
                setSeverity("error");
                setMessage(error.response.data.message);
                setOpen(true);
            })
    }

    const redirectToAmbiente = () => {
        return window.open("https://drive.google.com/drive/folders/16NhY2_WIaV0COXKOUL344qTAuU7WdrrW?usp=drive_link", '_blank', 'noopener,noreferrer');
    }

    const redirectToAnimazione = () => {
        return window.open("https://drive.google.com/drive/folders/1Oed1vvosjOtHN5WFgyV135EnbAabKPWf?usp=drive_link", '_blank', 'noopener,noreferrer');
    }

    return (
        <React.Fragment>
            <main className={classes.main}>
                <ResponsiveAppBar2/>

                <form onSubmit={handleSubmit(data => onSubmit(data))}>

                    <Container maxWidth="100%" component={"article"} style={{marginTop: 40}}>
                        <Grid container spacing={4}
                              style={{align: "center", justifyContent: 'center', alignItems: 'center'}}>
                            <Grid xs={xsGridCentral + 1}>
                                <Item elevation={4} width={'80%'}>
                                    <img
                                        className={classes.logo}
                                        src={logo}
                                        alt={"logo"}
                                        style={{width: "300px", height: "250px", marginBottom: -40}}
                                    />
                                    <Typography marginBottom={5} fontSize={25}>
                                        <b>Votazione Giuria di Qualità</b>
                                    </Typography>
                                    <Typography>
                                        Seleziona un cortometraggio ed esprimi la tua votazione
                                    </Typography>
                                    <CTextField
                                        control={control}
                                        options={cortometraggi.map(item => item.titolo).sort()}
                                        label={'Seleziona un cortometraggio'}
                                        type={'autocomplete'}
                                        name={'corto'}
                                    />
                                    <Button variant="contained"
                                            color="primary" onClick={redirectToAmbiente}
                                            style={{marginRight: 5, marginLeft: 5}}>
                                        Guarda
                                        Cortometraggi Ambiente
                                    </Button>
                                    <Button variant="contained"
                                            color="primary" onClick={redirectToAnimazione}
                                            style={{marginLeft: 5, marginRight: 5}}>
                                        Guarda
                                        Cortometraggi Animazione
                                    </Button>
                                    <CTextField
                                        control={control}
                                        defaultValue={0}
                                        type={'slider'}
                                        name={'regia'}
                                        helperText={'Regia: '}
                                    />
                                    <CTextField
                                        control={control}
                                        defaultValue={0}
                                        type={'slider'}
                                        name={'sceneggiatura'}
                                        helperText={'Sceneggiatura: '}
                                    />
                                    <CTextField
                                        control={control}
                                        defaultValue={0}
                                        type={'slider'}
                                        name={'fotografia'}
                                        helperText={'Fotografia: '}
                                    />
                                    <CTextField
                                        control={control}
                                        defaultValue={0}
                                        type={'slider'}
                                        name={'musica'}
                                        helperText={'Musica: '}
                                    />
                                    <CTextField
                                        control={control}
                                        defaultValue={0}
                                        type={'slider'}
                                        name={'montaggio'}
                                        helperText={'Montaggio: '}
                                    />
                                    <CTextField
                                        control={control}
                                        defaultValue={""}
                                        name={'commento'}
                                        label={"Inserisci un breve commento"}
                                        helperText={'Opzionale'}
                                        multiline={true}
                                        width={'85%'}
                                    />

                                    <Grid xs={12}>
                                        {loading ? <CircularProgress/> : <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            Invia
                                        </Button>}

                                    </Grid>
                                </Item>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                    <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
                <Footer/>
            </main>
        </React.Fragment>
    );
}

export default VotazioneGiuriaQualita;
