import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import homeImage from '../assets/plain_firefly.png'


function MainFeaturedPost() {

    return (
        <Paper
            sx={{
                position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${homeImage})`,
                height: 400,
            }}
        >
            {/* Increase the priority of the hero background image */}
            {/*{<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}*/}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.3)',
                }}
            />
            <Grid container>
                <Grid item md={6}>
                    <Box
                        sx={{
                            position: 'relative',
                            p: {xs: 3, md: 6},
                            pr: {md: 0},
                        }}
                    >
                        <Typography component="h1" variant="h3" color="inherit" gutterBottom fontSize={50}>
                            Selezione ufficiale cortometraggi in gara
                        </Typography>
                        <Typography variant="h5" color="inherit" paragraph>
                            Edizione 2024
                        </Typography>
                        {/*<Link variant="subtitle1" href="#">*/}
                        {/*    {"post.linkText"}*/}
                        {/*</Link>*/}
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default MainFeaturedPost;