import React from 'react';
import {BaseStyle} from "../styles/v2/BaseStyle";
import Grid from "@mui/system/Unstable_Grid";
import {styled} from "@mui/system";
import {withTheme} from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Paper, useMediaQuery} from "@mui/material";
import ResponsiveAppBar2 from "../components/ResponsiveAppBar2";
import homeImage from '../assets/wall_loghi.png';
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import wip from "../assets/work-in-progress.png";

const Item = styled(withTheme(Paper))(props => ({
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '60px',
    elevation: 4,
    borderRadius: 20,
    padding: 30,
    color: props.theme.palette.type === "light" ? 'black' : 'white',
    marginBottom: 40,
    // display: 'flex',
    // flexDirection: "column",
    // justifyContent: "center"
}));


export const Programma = () => {
    const classes = BaseStyle();
    const isMobile = useMediaQuery('(max-width:600px)');
    const xsGridCentral = isMobile ? 12 : 6;
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <main className={classes.main}>
                <ResponsiveAppBar2/>

                <Container maxWidth="100%" component={"article"} style={{
                    marginTop: 40, display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>

                    <Grid xs={12}
                          style={{display: 'flex', alignCenter: 'center', justifyContent: 'center', marginTop: 40}}>
                        <img
                            src={homeImage}
                            style={{
                                transform: isMobile ? 'scale(1)' : 'scale(0.9)',
                                boxShadow: 'rgba(0, 0, 0, 0.8) 0px 10px 100px',
                                borderRadius: '8px',
                                width: isMobile ? '90%' : '70%'
                            }}
                            alt={"logo"}
                        />
                    </Grid>
                    <Grid xs={xsGridCentral} style={{marginTop: 40, marginBottom: 40}}>
                        <Item elevation={4} width={'80%'}>
                            <Typography variant="h5" gutterBottom>
                                Programma della Prima Serata <br/>
                                <b>8 Agosto 2024</b><br/><br/>
                            </Typography>
                            <img
                                src={wip}
                                alt={"logo"}
                                style={{
                                    width: 100,
                                    height: 100,
                                    margin: 20
                                }}
                            />
                            {/*<Typography variant="subtitle1" gutterBottom>*/}
                            {/*    A partire dalle ore 21.30, proiezione dei cortometraggi nella sala all'aperto di*/}
                            {/*    piazza Olmo*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 1*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    DONDE LOS NINOS (Ambiente) - Durata: 20,00 minuti*/}
                            {/*    <br/>*/}
                            {/*    SAVE THE PLANET (Animazione) - Durata: 2,53 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 22,53 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 2*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    IL BINARIO MORTO (Ambiente) - Durata: 23,55 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 23,55 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 3*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    INSETTI (Ambiente) - Durata: 14,77 minuti*/}
                            {/*    <br/>*/}
                            {/*    48 GRADI (Ambiente) - Durata: 8,00 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 22,17 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Ospiti della serata*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    Giacomo Rizzo*/}
                            {/*    <br/>*/}
                            {/*    Sergio Muniz*/}
                            {/*    <br/>*/}
                            {/*    Diego Bianchi*/}
                            {/*    <br/>*/}
                            {/*    Gianfranco Phino (Spettacolo cabaret)*/}
                            {/*</Typography>*/}
                        </Item>
                        <Item elevation={4} width={'80%'}>
                            <Typography variant="h5" gutterBottom>
                                Programma della Seconda Serata <br/>
                                <b>9 Agosto 2024</b><br/><br/>
                            </Typography>
                            <img
                                src={wip}
                                alt={"logo"}
                                style={{
                                    width: 100,
                                    height: 100,
                                    margin: 20
                                }}
                            />
                            {/*<Typography variant="subtitle1" gutterBottom>*/}
                            {/*    A partire dalle ore 21.30, proiezione dei cortometraggi nella sala all'aperto di*/}
                            {/*    piazza Olmo*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 1*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    DONDE LOS NINOS (Ambiente) - Durata: 20,00 minuti*/}
                            {/*    <br/>*/}
                            {/*    SAVE THE PLANET (Animazione) - Durata: 2,53 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 22,53 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 2*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    IL BINARIO MORTO (Ambiente) - Durata: 23,55 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 23,55 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 3*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    INSETTI (Ambiente) - Durata: 14,77 minuti*/}
                            {/*    <br/>*/}
                            {/*    48 GRADI (Ambiente) - Durata: 8,00 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 22,17 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Ospiti della serata*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    Giacomo Rizzo*/}
                            {/*    <br/>*/}
                            {/*    Sergio Muniz*/}
                            {/*    <br/>*/}
                            {/*    Diego Bianchi*/}
                            {/*    <br/>*/}
                            {/*    Gianfranco Phino (Spettacolo cabaret)*/}
                            {/*</Typography>*/}
                        </Item>
                        <Item elevation={4} width={'80%'}>
                            <Typography variant="h5" gutterBottom>
                                Programma della Terza Serata <br/>
                                <b>10 Agosto 2024</b><br/><br/>
                            </Typography>
                            <img
                                src={wip}
                                alt={"logo"}
                                style={{
                                    width: 100,
                                    height: 100,
                                    margin: 20
                                }}
                            />
                            {/*<Typography variant="subtitle1" gutterBottom>*/}
                            {/*    A partire dalle ore 21.30, proiezione dei cortometraggi nella sala all'aperto di*/}
                            {/*    piazza Olmo*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 1*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    DONDE LOS NINOS (Ambiente) - Durata: 20,00 minuti*/}
                            {/*    <br/>*/}
                            {/*    SAVE THE PLANET (Animazione) - Durata: 2,53 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 22,53 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 2*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    IL BINARIO MORTO (Ambiente) - Durata: 23,55 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 23,55 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 3*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    INSETTI (Ambiente) - Durata: 14,77 minuti*/}
                            {/*    <br/>*/}
                            {/*    48 GRADI (Ambiente) - Durata: 8,00 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 22,17 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Ospiti della serata*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    Giacomo Rizzo*/}
                            {/*    <br/>*/}
                            {/*    Sergio Muniz*/}
                            {/*    <br/>*/}
                            {/*    Diego Bianchi*/}
                            {/*    <br/>*/}
                            {/*    Gianfranco Phino (Spettacolo cabaret)*/}
                            {/*</Typography>*/}
                        </Item>
                        <Item elevation={4} width={'80%'}>
                            <Typography variant="h5" gutterBottom>
                                Programma della Quarta Serata <br/>
                                <b>11 Agosto 2024</b><br/><br/>
                            </Typography>
                            <img
                                src={wip}
                                alt={"logo"}
                                style={{
                                    width: 100,
                                    height: 100,
                                    margin: 20
                                }}
                            />
                            {/*<Typography variant="subtitle1" gutterBottom>*/}
                            {/*    A partire dalle ore 21.30, proiezione dei cortometraggi nella sala all'aperto di*/}
                            {/*    piazza Olmo*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 1*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    DONDE LOS NINOS (Ambiente) - Durata: 20,00 minuti*/}
                            {/*    <br/>*/}
                            {/*    SAVE THE PLANET (Animazione) - Durata: 2,53 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 22,53 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 2*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    IL BINARIO MORTO (Ambiente) - Durata: 23,55 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 23,55 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Blocco 3*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    INSETTI (Ambiente) - Durata: 14,77 minuti*/}
                            {/*    <br/>*/}
                            {/*    48 GRADI (Ambiente) - Durata: 8,00 minuti*/}
                            {/*    <br/>*/}
                            {/*    Totale: 22,17 minuti*/}
                            {/*</Typography>*/}

                            {/*<Typography variant="h6" gutterBottom>*/}
                            {/*    Ospiti della serata*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1" paragraph>*/}
                            {/*    Giacomo Rizzo*/}
                            {/*    <br/>*/}
                            {/*    Sergio Muniz*/}
                            {/*    <br/>*/}
                            {/*    Diego Bianchi*/}
                            {/*    <br/>*/}
                            {/*    Gianfranco Phino (Spettacolo cabaret)*/}
                            {/*</Typography>*/}
                        </Item>
                    </Grid>
                </Container>
                <Footer/>
            </main>
        </React.Fragment>
    );
};

