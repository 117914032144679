import React from 'react';
import {BaseStyle} from "../styles/v2/BaseStyle";
import Grid from "@mui/system/Unstable_Grid";
import {styled} from "@mui/system";
import {withTheme} from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Button, Paper, useMediaQuery} from "@mui/material";
import ResponsiveAppBar2 from "../components/ResponsiveAppBar2";
import homeImage from '../assets/cff_home.jpg'
import homeImage2 from '../assets/logo_cff23.png'
import SendIcon from '@mui/icons-material/Send';
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";

const Item = styled(withTheme(Paper))(props => ({
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '60px',
    elevation: 4,
    borderRadius: 20,
    padding: 20,
    color: props.theme.palette.type === "light" ? 'black' : 'white',
    // display: 'flex',
    // flexDirection: "column",
    // justifyContent: "center"
}));


export const Home2 = () => {
    const classes = BaseStyle();
    const isMobile = useMediaQuery('(max-width:600px)');
    const xsGridCentral = isMobile ? 12 : 6;
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <main className={classes.main}>
                <ResponsiveAppBar2/>

                <Container maxWidth="100%" component={"article"} style={{
                    marginTop: 40, display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>

                    <Grid xs={12}
                          style={{display: 'flex', alignCenter: 'center', justifyContent: 'center', marginTop: 40}}>
                        <img
                            src={homeImage}
                            style={{
                                transform: isMobile ? 'scale(1)' : 'scale(0.9)',
                                boxShadow: 'rgba(0, 0, 0, 0.8) 0px 10px 100px',
                                borderRadius: '8px',
                                width: isMobile ? '90%' : ''
                            }}
                            alt={"logo"}
                        />
                        <img
                            src={homeImage2}
                            style={{
                                display: "none"
                            }}
                            alt={"alt"}/>

                    </Grid>
                    <Grid xs={xsGridCentral} style={{marginTop:40, marginBottom:40}}>
                        <Item elevation={4} width={'80%'}>
                            <Typography>
                                Entra nel mondo del cinema, esplora l'arte del racconto e fatti sentire! <br/>
                                Iscriviti a partire dal <b>1 Luglio</b> alla Giuria Giovani al Caselle Film
                                Festival.<br/>
                                Preparati a vivere un'esperienza unica e ad essere la voce dei futuri capolavori
                                cinematografici!<br/>
                            </Typography>
                            <Button variant="contained" endIcon={<SendIcon/>} onClick={() => {
                                navigate('/iscriviti')
                            }}>
                                Iscriviti
                            </Button>
                        </Item>
                    </Grid>
                </Container>
                <Footer/>
            </main>
        </React.Fragment>
    );
};

