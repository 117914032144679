import * as React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


function FeaturedPost({cortometraggio}) {

    return (
        <CardActionArea component="a" href="#">
            <Card sx={{display: 'flex'}} style={{
                backdropFilter: "blur(16px) saturate(200%)",
                webkitBackdropFilter: "blur(16px) saturate(200%)",
                backgroundColor: "rgba(17, 25, 40, 0.6)",
                borderRadius: "12px",
                border: "1px solid rgba(255, 255, 255, 0.125)",
            }}>
                <CardContent sx={{flex: 1}}>
                    <Typography component="h2" variant="h5" color={'white'}>
                        {cortometraggio.titolo}
                    </Typography>
                    <Typography variant="subtitle1" color={'white'}>
                        Regia di <b>{cortometraggio.regista}</b>, {cortometraggio.paese}
                    </Typography>
                    <Typography variant="subtitle1" paragraph color={'white'}>
                        {cortometraggio.sinossi}
                    </Typography>
                    <Typography variant="subtitle1" color={'white'} fontSize={15}>
                        Durata: {cortometraggio.durata} minuti
                    </Typography>
                </CardContent>
                <CardMedia
                    component="img"
                    sx={{width: 160, display: {xs: 'none', sm: 'block'}}}
                    image={cortometraggio.imageUrl}
                    alt={"post.imageLabel"}
                />
            </Card>
        </CardActionArea>
    );
}


export default FeaturedPost;