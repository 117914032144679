import {Autocomplete, Box, FormControl, Slider, TextField, Typography} from "@mui/material";
import React from "react";
import {styled} from "@mui/system";
import {useController} from "react-hook-form";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import moment from "moment/moment";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import 'moment/locale/it';

moment.locale('it-IT')

const StyledTextField = styled(TextField)({
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    '& .MuiOutlinedInput-root': {
        borderRadius: 20,
    },
});
const StyledAutocomplete = styled(Autocomplete)({
    marginTop: 20,
    marginBottom: 20,
    width: '85%',
    '& .MuiOutlinedInput-root': {
        borderRadius: 20,
    },
});
const StyledDatePickerField = styled(DatePicker)({
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    '& .MuiOutlinedInput-root': {
        borderRadius: 20,
    },
});
export const CTextField = ({
                               name,
                               control,
                               type,
                               label,
                               helperText,
                               inputProps,
                               autoFocus,
                               required,
                               multiline,
                               defaultValue,
                               options,
                               width,
                               shrink,
                               font
                           }) => {
    const {field} = useController({
        control,
        defaultValue: defaultValue ?? '',
        name,
    });


    const [inputValue, setInputValue] = React.useState('');

    if (type === 'date') {
        const handleDateChange = (date) => {
            const dateString = date ? moment(date).toISOString() : '';
            field.onChange(dateString);
        };

        return (
            <LocalizationProvider adapterLocale="it"
                                  dateAdapter={AdapterMoment}>
                <StyledDatePickerField
                    label={label}
                    helperText={helperText}
                    value={field.value !== '' ? moment(field.value) : null}
                    onChange={handleDateChange}
                    textField={(params) => <StyledTextField {...params} helperText={helperText}/>}
                    autoFocus={autoFocus}
                    // format="DD/MM/YYYY"
                />
            </LocalizationProvider>
        );
    } else if (type === 'slider') {
        return (
            <FormControl variant="standard" color="secondary" fullWidth required
                         style={{maxWidth: '80%', marginTop: 25, marginBottom: 25}}>
                <Typography
                    variant="subtitle1"
                    component="span">
                    {helperText}
                </Typography>
                <Slider
                    style={{
                        root: {
                            color: '#388e3c',
                            height: 8,
                        },
                        thumb: {
                            height: 24,
                            width: 24,
                            backgroundColor: '#fff',
                            border: '2px solid currentColor',
                            marginTop: -8,
                            marginLeft: -12,
                            '&:focus, &:hover, &$active': {
                                boxShadow: 'inherit',
                            },
                        },
                        active: {},
                        track: {
                            height: 8,
                            borderRadius: 4,
                        },
                        rail: {
                            height: 8,
                            borderRadius: 4,
                        },
                    }}
                    id="voto"
                    value={field.value}
                    onChange={field.onChange}
                    step={1}
                    min={0}
                    max={10}
                    marks={[...Array(11).keys()].map((value) => ({
                        value,
                        label: value,
                    }))}
                    valueLabelDisplay="auto"
                    // color='blue'
                />
            </FormControl>
        )
    } else if (multiline) {
        return (
            <StyledTextField
                label={label}
                helperText={helperText}
                variant="outlined"
                value={field.value}
                onChange={field.onChange}
                type={type}
                inputProps={inputProps}
                autoFocus={autoFocus}
                required={required}
                multiline
                rows={4}
                style={{width: width}}
            />
        );
    } else if (type === 'autocomplete') {
        return (
            <Box display={'flex'} justifyContent={'center'}>
                <StyledAutocomplete
                    value={field.value}
                    onChange={(_, newValue) => field.onChange(newValue)}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    id="controllable-states"
                    options={options}
                    renderInput={(params) => <TextField {...params} label={label}/>}
                />
            </Box>

        );
    }

    return (
        <StyledTextField
            label={label}
            helperText={helperText}
            variant="outlined"
            value={field.value}
            onChange={field.onChange}
            type={type}
            InputProps={inputProps}
            autoFocus={autoFocus}
            required={required}
            InputLabelProps={{shrink: shrink}}
        />
    );
}

export default CTextField;
