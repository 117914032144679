import jwt from 'jwt-decode'

export function getConfig() {
    let token = sessionStorage.getItem("token");
    if (token) {
        return {
            headers: {
                Authorization: "Bearer " + token,
            },
        }
    } else return {}
}

export function getRoles() {
    let token = sessionStorage.getItem("token");
    if (token) {
        const data = jwt(token); // decode your token here
        return data.roles
    } else return {}
}

export function wait(timeout) {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}