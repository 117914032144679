import React from 'react';
import {BaseStyle} from "../styles/v2/BaseStyle";
import Grid from "@mui/system/Unstable_Grid";
import {styled} from "@mui/system";
import {withTheme} from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Button, Paper, useMediaQuery} from "@mui/material";
import ResponsiveAppBar2 from "../components/ResponsiveAppBar2";
import homeImage from '../assets/cff_home.jpg'
import homeImage2 from '../assets/logo_cff23.png'
import SendIcon from '@mui/icons-material/Send';
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";

const Item = styled(withTheme(Paper))(props => ({
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '60px',
    elevation: 4,
    borderRadius: 20,
    padding: 20,
    color: props.theme.palette.type === "light" ? 'black' : 'white',
    // display: 'flex',
    // flexDirection: "column",
    // justifyContent: "center"
}));


export const ChiSiamo = () => {
    const classes = BaseStyle();
    const isMobile: boolean = useMediaQuery('(max-width:600px)');
    const xsGridCentral = isMobile ? 12 : 8;
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <main className={classes.main}>
                <ResponsiveAppBar2/>

                <Container maxWidth="100%" component={"article"} style={{
                    marginTop: 40, display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Grid xs={xsGridCentral} style={{marginTop: 40, marginBottom: 40}}>
                        <Grid item xs={12}>
                            <Item elevation={4} style={{width: '100%', padding: 20}}>
                                <Grid container>
                                    <Grid item xs={xsGridCentral}>
                                        <Typography margin={10}>
                                            Il Caselle Film Festival (CFF) rappresenta un appuntamento imperdibile per
                                            gli
                                            appassionati di
                                            natura e cinematografia, svolgendosi nel pittoresco contesto di Caselle in
                                            Pittari,
                                            incastonato
                                            nel Parco Nazionale del Cilento e Vallo di Diano. Questo festival è dedicato
                                            esclusivamente a
                                            tematiche ambientali e naturalistiche, mirando a sensibilizzare il pubblico
                                            sui temi
                                            della
                                            conservazione e della sostenibilità attraverso l'arte del cinema.<br/><br/>

                                            Il CFF non è solo una vetrina per film e documentari, ma si configura come
                                            un vero e
                                            proprio
                                            movimento culturale che cerca di influenzare positivamente la consapevolezza
                                            ecologica degli
                                            spettatori. Attraverso la selezione di opere che spaziano dai cortometraggi
                                            ai
                                            documentari di
                                            lunga durata, il festival offre una panoramica ampia e variegata delle sfide
                                            e delle
                                            bellezze
                                            del nostro pianeta. <br/><br/>

                                            La scorsa edizione del festival ha visto una partecipazione entusiastica sia
                                            da
                                            parte di
                                            cineasti emergenti che di registi affermati, con una ricca offerta di opere
                                            provenienti da tutto
                                            il mondo. I lavori presentati hanno spaziato da intime narrazioni locali a
                                            racconti
                                            di portata
                                            globale, tutti accomunati dall'impegno a portare alla luce questioni
                                            ambientali
                                            urgenti. Tra i
                                            momenti più apprezzati vi sono stati workshop, dibattiti e incontri con
                                            esperti e
                                            attivisti
                                            ambientali, che hanno arricchito l'esperienza dei partecipanti fornendo
                                            approfondimenti critici
                                            e stimolando un dialogo costruttivo.<br/>
                                        </Typography>
                                    </Grid>

                                    {!isMobile && (<Grid item xs={4}>
                                        <img
                                            src={"https://upload.wikimedia.org/wikipedia/commons/9/9f/Caselle_in_Pittari.jpg"}
                                            style={{
                                                width: '70%',
                                                boxShadow: '10px 10px 20px grey', /* Aggiunge un'ombra */
                                                margin: 20
                                            }}/>
                                    </Grid>)}
                                </Grid>

                                <Grid container>
                                    {!isMobile && (<Grid item xs={4}>
                                        <img
                                            src={"https://www.cilentomania.it/wp-content/uploads/2019/12/01_b.jpg"}
                                            style={{
                                                width: '100%',
                                                boxShadow: '10px 10px 20px grey', /* Aggiunge un'ombra */
                                                margin: 20
                                            }}/>
                                    </Grid>)}
                                    <Grid item xs={xsGridCentral}>
                                        <Typography margin={10}>
                                            Inoltre, il CFF premia l'innovazione e la creatività nel campo
                                            cinematografico
                                            ambientale, con
                                            categorie che includono miglior film, miglior regia e premi speciali per
                                            giovani
                                            cineasti. La
                                            cerimonia di premiazione, momento culminante del festival, non solo celebra
                                            i
                                            vincitori, ma
                                            sottolinea l'importanza della narrazione cinematografica come strumento di
                                            cambiamento.<br/><br/>

                                            La partecipazione al festival è completamente gratuita, rendendo gli eventi
                                            accessibili a un
                                            vasto pubblico. Questo approccio democratico è fondamentale per il CFF, che
                                            si
                                            propone di essere
                                            un punto di incontro inclusivo per tutti coloro che sono interessati al
                                            futuro del
                                            nostro
                                            ambiente. La disponibilità dei posti è limitata, quindi è consigliabile
                                            prenotare in
                                            anticipo
                                            per assicurarsi la possibilità di partecipare a questo evento
                                            unico.<br/><br/>

                                            Con il suo impegno nel promuovere la cultura della sostenibilità attraverso
                                            il
                                            cinema, il
                                            Caselle Film Festival si conferma un evento di riferimento nel panorama dei
                                            festival
                                            cinematografici tematici, un appuntamento che continua a crescere e a
                                            ispirare, anno
                                            dopo
                                            anno.<br/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>

                    <Grid xs={xsGridCentral} style={{marginTop: 40, marginBottom: 40}}>
                        <Grid item xs={12}>
                            <Item elevation={4} style={{width: '100%', padding: 20}}>
                                <Typography variant="h5" style={{marginBottom: 20}}>
                                    Entra nel mondo del cinema, esplora l'arte del racconto e fatti sentire!
                                </Typography>
                                <Typography>
                                    Iscriviti a partire dal <b>1 Luglio</b> alla Giuria Giovani al Caselle Film
                                    Festival.<br/>
                                    Preparati a vivere un'esperienza unica e ad essere la voce dei futuri capolavori
                                    cinematografici!
                                </Typography>
                                <Button variant="contained" color="primary" endIcon={<SendIcon/>} onClick={() => {
                                    navigate('/iscriviti');
                                }}>
                                    Iscriviti
                                </Button>
                            </Item>
                        </Grid>
                    </Grid>
                </Container>
                <Footer/>
            </main>
        </React.Fragment>
    );
};

