//APP
export const SET_THEME                                        = "SET_THEME";
export const SET_LANG                                         = "SET_LANG";
export const SET_TAB                                          = "SET_TAB";
export const SET_ROLE                                         = "SET_ROLE";
export const SET_OPEN_SNACK                                   = "SET_OPEN_SNACK";
export const SET_CLOSE_SNACK                                  = "SET_CLOSE_SNACK";
export const GET_EVENTS_START                                 = "GET_EVENTS_START";
export const GET_EVENTS_SUCCESS                               = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL                                  = "GET_EVENTS_FAIL";
export const GENERATE_EVENT_START                             = "GENERATE_EVENT_START";
export const GENERATE_EVENT_SUCCESS                           = "GENERATE_EVENT_SUCCESS";
export const GENERATE_EVENT_FAIL                              = "GENERATE_EVENT_FAIL";
